// rubrika/podrubbrike
$m: section;

.#{$m} {

    &--loadmorewrap {
    	@include min-screen($xlg) {
    	    margin: -71px 0 -10px;
    	}
    }

    &--special {
        .container__height_wrap {
            @include min-screen($xlg) {
                display: -webkit-flex; /* Safari */
                -webkit-flex-direction: row; /* Safari 6.1+ */
                display: flex;
                flex-direction: row;
            }
        }

        .column_sidebar {
            @include min-screen($xlg) {
                padding: 0;
                -webkit-flex: 1;  /* Safari 6.1+ */
                -ms-flex: 1;  /* IE 10 */
                flex: 1;
            }
        }

        .js_sticky {
            @include min-screen($lg) {
                top: 0;
            }

            @include min-screen($xlg) {
                top: 70px;
            }
        }
    }

    &__noresult_block {
        margin: 20px 0 0 0;

        @include min-screen($lg) {
            margin: -18px 0 0 -18px;
        }

        @include min-screen($xlg) {
            margin: -2px 0 0 -22px;
        }
    }

    &__noresult_title {
        padding: 0;
        font: $font_prim--bold;
        @include font-size(27);
        @include line-height(24);
        @include letter-spacing(-25);
        color: $grey_00;
        text-align: center;

        @include min-screen($lg) {
            @include font-size(24);
            @include line-height(40);
        }

        @include min-screen($xlg) {
            @include font-size(36);
            @include line-height(40);
        }
    }

    &__noresult_desc {
        margin: 10px 0 0 0;
        font: $font_prim;
        @include font-size(16);
        @include line-height(24);
        text-align: center;

        @include min-screen($lg) {
            margin: 4px 0 0 -15px;
            @include font-size(16);
            @include line-height(24);
            @include letter-spacing(10);
        }

        @include min-screen($xlg) {
            margin: 11px 0 0 -15px;
            @include font-size(17);
            @include line-height(30);
            @include letter-spacing(10);
        }

        a {
            box-shadow: inset 0 -4px 0 $grey_04;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                box-shadow: inset 0 -3px 0 $grey_04;
            }

            &:hover {
                box-shadow: inset 0 -4px 0 $grey_06;

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    box-shadow: inset 0 -3px 0 $grey_06;
                }
            }
        }
    }

    &--pod-strehom {
        .share_widget {
            @include min-screen($lg) {
                margin: 64px 15px 20px;
            }

            @include min-screen($xlg) {
                margin: 81px 15px 30px;
            }
        }

        .fold_section_mid--banner .dfp_banner--Leaderboard > div {
            @include min-screen($lg) {
                margin: 20px 0 0;
            }

            @include min-screen($xlg) {
                margin: 30px 0 0;
            }
        }

        .fold_home_b--part1 {
            @include min-screen($xlg) {
                margin: 0 auto;
            }
        }

        .fold_section_feed {
            @include min-screen($lg) {
                margin: 0 auto;
            }
        }
    }

    &--avto {
        .dfp_banner--Leaderboard {
            margin: 0;

            div[id$="__container__"],
            div[id$="_ad_container"] {
                margin: 20px 0 0;

                @include min-screen($lg) {
                    margin: 30px auto 10px;
                }
            }
        }

        .rss_feed_widget {
            margin-top: 30px;
        } 
    }
}
