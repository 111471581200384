.container_specials {
	background: #1e1e1e;
}

.container_multimedia {
	background: #1e1e1e;

	.card__title {
		color: #fff;
	}
}

.container_latest {
	background: #ffef99;
}
