$m: column_widget;

.#{$m} {

    @include max-screen($lg - 1) {
        margin-top: 31px;
    }

    .#{$m}__wrap {

        clear: both;
        font-size: 0;
        width: 100%;
        vertical-align: bottom;

        @include min-screen($sm) {
            margin: 0 25px 20px;
        }

        @include min-screen($lg) {
            margin: 12px 15px 20px;
            display: inline-flex;
        }

        @include min-screen($xlg) {
            margin: 30px 15px 20px;
        }
    }

    .#{$m}__block {

        vertical-align: bottom;
        position: relative;
        float: left;
        box-sizing: border-box;
        overflow: hidden;
        width: 256px;
        height: 355px;
        padding-top: 12px;
        margin-right: 30px;
        color: $white;
        background: $grey_01;

        @include min-screen($lg) {
            padding-top: 12px;
            width: 311px;
            height: auto;
        }

        @include min-screen($xlg) {
            padding-top: 25px;
            width: 406px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .#{$m}__author_link {
        display: block;
        position: relative;
        height: 80px;

        @include min-screen($xlg) {
            height: 120px;
        }

        &:hover {
            .#{$m}__author_name {
                color: $grey_04;
            }
        }
    }

    .#{$m}__figure {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-left: 6px solid $yellow_prim;

        picture > img {
            width: 100%;
            height: auto;
        }

        @include min-screen($xlg) {
            width: 120px;
            height: 120px;
            margin-right: 22px;
            border-left: 12px solid $yellow_prim;
        }
    }

    .#{$m}__author_name {
        position: absolute;
        bottom: -4px;
        right: 0;
        left: 97px;
        padding: 0;
        font: $font_sec;
        @include font-size(16);
        @include line-height(20);
        @include letter-spacing(100);
        color: $white;
        transition: color 0.3s;

        @include min-screen($sm) {
            bottom: 12px;
            &.without_img {
                left: 12px;
            }
        }

        @include min-screen($lg) {
            bottom: 16px;
            left: 97px;
            &.without_img {
                left: 12px;
            }
        }

        @include min-screen($xlg) {
            top: 50px;
            bottom: auto;
            left: 152px;
            @include font-size(22);
            @include line-height(28);
            &.without_img {
                left: 22px;
            }
        }

        > span {

            margin-bottom: 9px;
            display: block;
            font: $font_prim--bold;
            @include font-size(12);
            @include letter-spacing(100);
            color: $grey_03;
            text-transform: uppercase;

            @include screen($lg, $xlg - 1) {
                margin-bottom: 9px;
            }
        }
    }

    .#{$m}__list {

        list-style-type: none;
        margin: 0;
        padding: 0;
        clear: both;
    }

    .#{$m}__list_item {
        box-sizing: border-box;
        position: relative;
        margin: 0;
        padding-bottom: 12px;
        font: $font_prim;
        padding-left: 12px;
        @include font-size(16);
        @include line-height(22);

        &:before {
            content: " ";
            position: absolute;
            left: 0;
            top: 6px;
            display: block;
            width: 6px;
            height: 12px;
            background: $yellow_prim;
        }

        @include min-screen($lg) {
            padding-bottom: 12px;
        }

        @include min-screen($xlg) {
            padding-left: 25px;
            padding-bottom: 23px;
            @include font-size(18);
            @include line-height(22);

            &:before {
                top: 6px;
                width: 12px;
            }
        }

        &:first-child {
            overflow: hidden;
            position: relative;
            height: 120px;
            margin-bottom: 24px;
            padding: 16px 12px;
            @include font-size(22);
            @include line-height(28);
            @include letter-spacing(-25);

            &:before {
                content: " ";
                position: absolute;
                top: auto;
                bottom: 0px;
                left: 12px;
                display: block;
                width: 232px;
                height: 0;
                border-bottom: 1px solid $grey_02;
            }

            @include min-screen($sm) {
                height: 85px;
                margin-bottom: 23px;
                padding: 19px 12px;

                &:before {
                    left: 12px;
                    width: 180px;
                }
            }

            @include min-screen($lg) {

                padding: 19px 12px;
                height: 91px;

                &:before {
                    left: 12px;
                    width: 180px;
                }
            }

            @include min-screen($xlg) {

                height: 150px;
                margin-bottom: 30px;
                padding: 30px 25px;
                @include font-size(30);
                @include line-height(44);
                @include letter-spacing(-25);

                &:before {
                    left: 25px;
                    width: 180px;
                }
            }
        }
    }

    .#{$m}__article_link {
        color: $white;
        transition: color 0.3s;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &:hover {
            color: $grey_04;
        }
    }
}

.#{$m} {

    .#{$m}__wrap {

        @include max-screen($lg - 1) {

            margin: 12px 0 15px;
            margin: -12px 0 12px;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;

            .#{$m}__block {
                white-space: normal;

                float: none;
                display: inline-block;
                margin-top: 12px;
                margin-right: 12px;

                &:first-child {
                    margin-left: 25px;
                }

                &:last-child {
                    margin-right: 25px;
                }
            }
        }

        @include max-screen($sm - 1) {
            .#{$m}__block {

                &:first-child {
                    margin-left: 12px;
                }

                &:last-child {
                    margin-right: 12px;
                }
            }
        }
    }
}
