$m: topnews_widget;
.#{$m} {

    padding: 0 14px;

    @include min-screen($sm) {
        padding: 0 15px;
    }

    @include min-screen($xlg) {
        .main_tile__content {
            padding-top: 8px;
        }
    }
}
